import React from 'react'
import styled from 'styled-components'
import { Card as GlobalCard, Container as GlobalContainer } from '../../styles'
import QuiqupLogo from '../../assets/quiqup-logo.png'
import { ReactComponent as NoOrder } from '../../assets/no-order.svg'

const Container = styled(GlobalContainer)`
  flex-direction: column;
`

const Card = styled(GlobalCard)`
  justify-content: center;
  h2 {
    font-size: 2.25rem;
    color: ${(p) => p.theme.midnight500};
    font-weight: 300;
  }
  p {
    color: ${(p) => p.theme.slate400};
  }
  svg {
    margin: 0 auto;
  }
  h2,
  p {
    text-align: center;
  }
  .powered-by-quiqup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 10px auto 0;
  }

  .title {
    font-size: 1.12rem;
  }

  .subtitle {
    font-size: 0.75rem;
  }

  @media (min-width: 470px) {
    width: 400px;
    height: 400px;
    border-radius: 4px;
    box-shadow: 4px 3px 5px 0px rgb(197 197 197 / 75%);
  }
`

type NotFoundCardProps = {
  title: String
  subTitle?: String
}
export default function NotFoundCard({ title, subTitle }: NotFoundCardProps) {
  return (
    <Container data-testid="invalid-order">
      <Card>
        <NoOrder />
        <h2>Ooops!</h2>
        <p className="title">{title}</p>
        {subTitle ? <p className="subtitle">{subTitle}</p> : null}
        <div className="powered-by-quiqup">
          Delivered by <img alt="Quiqup" src={QuiqupLogo} width={160} />
        </div>
      </Card>
    </Container>
  )
}
