import styled from 'styled-components'
import theme from '../theme'

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (min-width: ${theme.minBreakpoints.mobile}) {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
`

export const Card = styled.div`
  h3,
  h2,
  h4 {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  background: #fff;
  margin: 0 auto;
  padding: 24px 16px 16px;
  width: 100%;

  @media (min-width: ${theme.minBreakpoints.mobile}) {
    width: 470px;
    height: 670px;
    border-radius: 4px;
    box-shadow: 4px 3px 5px 0px rgb(197 197 197 / 75%);
    padding: 40px 25px 20px;
  }
`
