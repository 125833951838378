import React from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'
import UpdateAddressBtn from './update-address-btn/update-address-btn'
import theme from '../../theme'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'

const VerifiedIcon = styled(CheckCircleFilled)`
  color: #00a854;
  margin-right: 4px;
`
const VerifiedMessage = styled.div`
  color: ${theme.slate500};
  font-size: 10px;
  line-height: 21px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`

type Props = {}

function DestinationInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { orderDetails } = useTracking()

  if (
    !orderDetails ||
    !orderDetails.destination ||
    !orderDetails.uuid ||
    orderDetails?.serviceKind === 'partner_return'
  ) {
    return null
  }

  return (
    <DetailCard ref={ref} title="📍 Delivery address">
      <Content>
        <div>
          {orderDetails.destination.contactName} -{' '}
          {orderDetails.destination.address.address1}
        </div>
        <UpdateAddressBtn
          address={orderDetails.destination}
          orderUuid={orderDetails.uuid}
          state={orderDetails.state}
          serviceKind={orderDetails.serviceKind}
          orderId={orderDetails.id}
          addressType="destination"
          hide={orderDetails.stateChanges.some(
            (stateChange) => stateChange.toState === 'delivery_complete'
          )}
        />
        {orderDetails.destination.checked ? (
          <VerifiedMessage>
            <VerifiedIcon />
            Address confirmed
          </VerifiedMessage>
        ) : null}
      </Content>
    </DetailCard>
  )
}

export default React.forwardRef(DestinationInfo)
