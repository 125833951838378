import React from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useTracking } from '../../hooks/useTracking'

type FormValues = {
  orderId: string
}

export default function FindOrderForm() {
  const { track } = useAnalytics()
  const { isLoadingOrder, getOrder } = useTracking()

  const onFinish = async (values: FormValues) => {
    track('Website Tracking Page Track Button', {
      action: 'submit',
      category: 'tracking',
      product: 'TrackOrder',
      orderId: values.orderId,
    })
    getOrder({ orderId: values.orderId })
  }

  return (
    <>
      <Form
        name="tracking-form"
        onFinish={onFinish}
        autoComplete="on"
        layout="vertical"
      >
        <Form.Item
          label="Reference No"
          name="orderId"
          rules={[
            {
              required: true,
              message: 'Please enter the Reference No',
            },
          ]}
        >
          <Input
            placeholder="8994240"
            data-testid="reference-no-input"
            name="quiqOrderId"
            autoComplete="quiq one-time-code"
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Row justify="end" style={{ marginTop: 8 }}>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                block
                data-testid="track-button"
                disabled={isLoadingOrder}
                loading={isLoadingOrder}
              >
                Track
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}
