import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion'
import theme from '../../theme'

type Props = PropsWithChildren<{
  title: ReactNode
  note?: ReactNode
}>

const Container = styled.div`
  background-color: #fff;
  min-height: 80px;
  border-radius: 6px;
  padding: 11px 19px 15px 19px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
`
const CardTitle = styled.div`
  text-align: left;
  font-size: 10px;
  line-height: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${theme.midnight500};
`
const Content = styled.div`
  margin-top: 10px;
`
const CardNote = styled.div`
  color: ${theme.slate500};
  font-size: 10px;
  line-height: 21px;
`
const NoteIcon = styled(InfoCircleOutlined)`
  margin-right: 4px;
`

function DetailCard(
  { title, note, children }: Props,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <motion.div
      ref={ref}
      layout
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.6, type: 'spring' }}
    >
      <Container>
        <CardTitle>{title}</CardTitle>
        <Content>{children}</Content>
        {note ? (
          <CardNote>
            <NoteIcon />
            {note}
          </CardNote>
        ) : null}
      </Container>
    </motion.div>
  )
}

export default React.forwardRef(DetailCard)
