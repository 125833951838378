import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import theme from '../../../theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  max-width: 429px;
`
const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: ${theme.midnight500};
`
const Message = styled.div`
  text-align: center;
  font-size: 16px;
  color: ${theme.midnight500};
  margin-top: 8px;
`
const Info = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${theme.cornFlower500};
  margin-top: 8px;
`
const StyledModal = styled(Modal)`
  max-width: 391px;

  @media (max-width: ${theme.minBreakpoints.tablet}) {
    padding: 0 19px;
  }
`

type Props = {
  isOpen: boolean
  onClose: (value: boolean) => void
}

export default function UpdateAddressUnavailable(props: Props) {
  return (
    <StyledModal
      open={props.isOpen}
      maskClosable
      centered
      onCancel={() => props.onClose(false)}
      footer={null}
      width="100%"
    >
      <Container>
        <Title>Ooops! 😅</Title>
        <Message>
          Your order is out for delivery, and we can't update the address right
          now.
        </Message>
        <Info>Feel free to come back once it's at our depot.</Info>
      </Container>
    </StyledModal>
  )
}
