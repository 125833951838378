import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react'
import { useNavigate } from 'react-router'
import ApiClient from '@quiqupltd/quiqupjs/lib/network/api-client'
import { useEnvVars } from './useEnvVars'
import { useMutation } from 'react-query'
import useTrackingSocket from './useTrackingSocket'
import { Order } from '../types/Order'
import { externalStatus } from '../lib/status'
import {
  getOrderedStateChanges,
  getStateChangesWithDate,
  getUniqueStateChanges,
} from '../components/order-details/utils'
import { useAnalytics } from './useAnalytics'
import { notification } from 'antd'

const UUID_REGEX =
  /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

type GetOrderByIdParams = {
  orderId: string
  phone?: string
  dialingCode?: string
  replace?: boolean
  triggerAlert?: boolean
}
type GetOrderResponse = {
  order: Order
  trackingUrl: string
}
const getOrder =
  (url: string) =>
  async (params: GetOrderByIdParams): Promise<GetOrderResponse> => {
    const isUuid = UUID_REGEX.test(params.orderId)
    const countryCode = isUuid ? undefined : params.dialingCode
    const phoneNumber = isUuid ? undefined : params.phone
    try {
      const response = await ApiClient.get({
        path: url,
        includeAuth: false,
        params: {
          order_id: params.orderId,
          country_code: countryCode,
          phone_number: phoneNumber,
        },
      })

      if (params.dialingCode && params.phone && !response.order.uuid) {
        throw new Error('wrong phone number')
      }

      if (!response.trackingUrl && !response.order) {
        throw new Error('no tracking url')
      }

      return {
        order: response.order,
        trackingUrl: response.trackingUrl,
      }
    } catch (error) {
      throw error
    }
  }

type TrackingContextType = {
  isLoadingOrder: boolean
  isOrderError: boolean
  getOrder: (params: GetOrderByIdParams) => void
  orderDetails: Order | undefined
  invalidToken: boolean
  liveOrder: any
  mapCenter: any
  hasMap: boolean
  canLeaveAtDoor: boolean
  uniqueStateChanges: any
}

export const TrackingContext = createContext<TrackingContextType>({
  isLoadingOrder: false,
  isOrderError: false,
  getOrder: () => {},
  orderDetails: undefined,
  invalidToken: false,
  liveOrder: undefined,
  mapCenter: undefined,
  hasMap: false,
  canLeaveAtDoor: false,
  uniqueStateChanges: undefined,
})

export const useTracking = () => useContext(TrackingContext)

export const TrackingProvider = ({ children }: PropsWithChildren) => {
  const [orderDetails, setOrderDetails] = useState<Order | undefined>(undefined)
  const { env } = useEnvVars()
  const { track } = useAnalytics()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const getOrderUrl = `${env.EXCORE_URL}/order_management/tracking`
  const {
    mutate: getOrderMutate,
    isLoading: isLoadingOrder,
    isError: isOrderError,
  } = useMutation({
    mutationFn: getOrder(getOrderUrl),
    onSuccess: (data: GetOrderResponse, callParams) => {
      setOrderDetails(data.order)
      track('Website Tracking Page Track Success', {
        action: 'redirect',
        category: 'tracking',
        product: 'TrackOrder',
        phone: callParams.phone,
        orderId: callParams.orderId,
      })

      if (data.trackingUrl) {
        navigate(data.trackingUrl, { replace: callParams.replace })

        return
      }

      navigate(`/${data.order.uuid ?? data.order.id}`, {
        replace: callParams.replace,
      })
    },
    onError: (error: any, callParams) => {
      if (callParams.triggerAlert !== undefined && !callParams.triggerAlert) {
        return
      }

      if (error.message === 'wrong phone number') {
        api.error({
          message: 'Wrong phone number',
          description:
            'Please use the phone number you used during order placement and try again.',
          duration: 10,
        })
        return
      }

      setOrderDetails(undefined)
      api.error({
        message: "We couldn't find your order",
        description: 'Please check the reference number and try again.',
        duration: 10,
      })
    },
  })
  const { invalidToken, liveOrder, mapCenter } = useTrackingSocket({
    token: orderDetails?.destination?.trackingToken,
    missionId: orderDetails?.mission?.id,
    shouldConnect: Boolean(
      !isLoadingOrder && !isOrderError && orderDetails?.id
    ),
  })

  const stateChangesWithDate = useMemo(
    () => getStateChangesWithDate(orderDetails?.stateChanges ?? []),
    [orderDetails?.stateChanges]
  )
  const stateChanges = useMemo(
    () => getOrderedStateChanges(stateChangesWithDate),
    [stateChangesWithDate]
  )
  const uniqueStateChanges = useMemo(
    () => getUniqueStateChanges(stateChanges),
    [stateChanges]
  )
  const hasToken = Boolean(orderDetails?.destination?.trackingToken)
  const hasMap =
    Boolean(
      hasToken &&
        !invalidToken &&
        ((orderDetails?.serviceKind !== 'partner_return' &&
          externalStatus[uniqueStateChanges[0]?.toState ?? ''] ===
            'Delivering') ||
          (orderDetails?.serviceKind === 'partner_return' &&
            ['out_for_collection', 'out_for_return'].includes(
              orderDetails?.state ?? ''
            )))
    ) && orderDetails?.serviceKind !== 'partner_export'
  Boolean(liveOrder?.features?.length) && Boolean(mapCenter)

  const canLeaveAtDoor = useMemo(
    () =>
      Boolean(
        orderDetails?.requiredDocuments &&
          [
            externalStatus.at_depot,
            externalStatus.collected,
            externalStatus.out_for_collection,
            externalStatus.out_for_delivery,
            externalStatus.pending,
            externalStatus.ready_for_collection,
            externalStatus.return_to_origin,
          ].includes(externalStatus[uniqueStateChanges[0]?.toState ?? 0])
      ),
    [orderDetails?.requiredDocuments, uniqueStateChanges]
  )

  const trackingContextValue = {
    isLoadingOrder,
    isOrderError,
    getOrder: getOrderMutate,
    orderDetails,
    invalidToken,
    liveOrder,
    mapCenter,
    hasMap,
    canLeaveAtDoor,
    uniqueStateChanges,
  }

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      {children}
      {contextHolder}
    </TrackingContext.Provider>
  )
}
