import React, { RefObject, useEffect } from 'react'
import { externalStatus } from '../../lib/status'
import { StateChange } from '../../types/Order'
import { CardWrapper } from '../../v2/styles'
import { useTracking } from '../../hooks/useTracking'

type ConditionalCardWrapperProps = {
  children: React.ReactNode
}
export const ConditionalCardWrapper = ({
  children,
}: ConditionalCardWrapperProps) => {
  const { hasMap: condition } = useTracking()
  return condition ? <CardWrapper>{children}</CardWrapper> : children
}

export function getStateChangesWithDate(
  stateChanges: StateChange[]
): StateChange[] {
  return stateChanges.map((s: any) => ({
    ...s,
    occurredAt: new Date(s.occurredAt),
  }))
}

export function getOrderedStateChanges(
  stateChanges: StateChange[]
): StateChange[] {
  return stateChanges.sort((a: any, b: any) => b.occurredAt - a.occurredAt)
}

export function getUniqueStateChanges(
  stateChanges: StateChange[]
): (StateChange | undefined)[] {
  const uniqueStates: Set<string> = new Set(
    stateChanges.map((stateChange) => externalStatus[stateChange.toState])
  )

  return Array.from(uniqueStates).map((uniqueState) =>
    stateChanges.find(
      (stateChange) => externalStatus[stateChange.toState] === uniqueState
    )
  )
}

type AnyEvent = MouseEvent | TouchEvent

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: AnyEvent) => void
) => {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const target = event.target as Node
      if (!ref.current || ref.current.contains(target)) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const EventMapper: {
  [key: string]: {
    event: string
    action: string
    category: string
  }
} = {
  'call driver': {
    event: 'Call Driver Button',
    action: 'Click',
    category: 'Contact',
  },
  'change address': {
    event: 'Change address Link',
    action: 'Click',
    category: 'Contact',
  },
  'change address unavailable': {
    event: 'Change address Link',
    action: 'Click',
    category: 'Contact',
  },
  'leave at door': {
    event: 'Leave At Door Switch',
    action: 'Toggle',
    category: 'Tracking',
  },
  whatsapp: {
    event: 'Whatsapp Button',
    action: 'Click',
    category: 'Contact',
  },
  'phone number': {
    event: 'Phone Number Button',
    action: 'Click',
    category: 'Contact',
  },
  'track dhl': {
    event: 'Track DHL Button',
    action: 'Click',
    category: 'Tracking',
  },
}
