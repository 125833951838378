import React from 'react'
import { TopLogo } from '../../v2/styles'
import QuiqupLogo from '../../assets/quiqup-logo.png'
import { useTracking } from '../../hooks/useTracking'

export default function Logo() {
  const { orderDetails } = useTracking()
  if (!orderDetails) {
    return null
  }
  return (
    <TopLogo>
      {orderDetails?.partner?.logoUrl ? (
        <img
          data-testid="brand-logo"
          src={orderDetails?.partner?.logoUrl}
          alt={`${orderDetails?.partner?.name} Logo`}
        />
      ) : (
        <img alt="Quiqup" src={QuiqupLogo} width={160} />
      )}
    </TopLogo>
  )
}
