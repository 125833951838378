import React, { forwardRef } from 'react'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'

type Props = {}
const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`
const StyledValue = styled.div`
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: ${theme.midnight800};
`

const StyledNote = styled.div`
  color: ${theme.slate500};
  font-size: 10px;
  line-height: 21px;
`
function OtpInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { orderDetails } = useTracking()
  if (
    !orderDetails ||
    !orderDetails?.otpCode ||
    !(
      (orderDetails.requiredDocuments ?? []).includes('otp') &&
      orderDetails.otpCode &&
      orderDetails.serviceKind !== 'partner_return'
    )
  ) {
    return null
  }

  return (
    <DetailCard ref={ref} title={'🔑 OTP'}>
      <Content>
        <StyledValue>{orderDetails?.otpCode}</StyledValue>
        <StyledNote>
          This code will be required to complete the delivery
        </StyledNote>
      </Content>
    </DetailCard>
  )
}
export default forwardRef(OtpInfo)
