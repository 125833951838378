import React, { forwardRef } from 'react'
import { WHATSAPP_URL, generateWhatsappMessage } from '../../utils/constants'
import { WhatsAppOutlined } from '@ant-design/icons'
import { useAnalytics } from '../../hooks/useAnalytics'
import { EventMapper } from './utils'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'

type Props = {}
const Content = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`
const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const WhatsappIcon = styled(WhatsAppOutlined)`
  color: white;
  border-radius: 99px;
  background-color: #25d366;
  padding: 6px;
  font-size: 24px;
`

function CustomerServiceInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { track } = useAnalytics()
  const { orderDetails } = useTracking()

  if (!orderDetails) {
    return null
  }

  const handleTrackEvent = (event: string) => {
    track(`Website Tracking Page ${EventMapper[event].event}`, {
      action: EventMapper[event].action,
      category: EventMapper[event].category,
      product: 'TrackOrder',
      orderId: String(orderDetails?.id),
    })
  }

  return (
    <DetailCard ref={ref} title={'📞 Customer service'}>
      <Content>
        <div>We are here to help</div>
        <IconsContainer>
          <a
            href={`${WHATSAPP_URL}${generateWhatsappMessage(
              orderDetails?.id,
              'help'
            )}`}
            onClick={() => handleTrackEvent('whatsapp')}
          >
            <WhatsappIcon />
          </a>
        </IconsContainer>
      </Content>
    </DetailCard>
  )
}

export default forwardRef(CustomerServiceInfo)
