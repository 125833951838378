import { InfoCircleOutlined } from '@ant-design/icons'
import React, { forwardRef } from 'react'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'
type Props = {}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`
const Note = styled.div`
  color: ${theme.slate500};
  font-size: 10px;
  line-height: 21px;
`
const InfoCircleIcon = styled(InfoCircleOutlined)`
  margin-right: 4px;
`
function PaymentInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { orderDetails } = useTracking()
  if (!orderDetails || orderDetails.serviceKind === 'partner_return') {
    return null
  }

  return (
    <DetailCard ref={ref} title={'💳 Pay on delivery'}>
      <Content>
        {orderDetails?.paymentMode === 'pre_paid' ? (
          <div>This order is prepaid</div>
        ) : orderDetails?.paymentMode === 'paid_on_delivery' ? (
          <>
            <div>Have {orderDetails?.paymentAmount}AED ready</div>
            <Note>
              <InfoCircleIcon />
              Cash or card payment options available
            </Note>
          </>
        ) : null}
      </Content>
    </DetailCard>
  )
}
export default forwardRef(PaymentInfo)
