const externalStatus: { [key: string]: string } = {
  at_depot: 'Collected',
  cancelled: 'Cancelled',
  collected: 'Collected',
  delivery_complete: 'Delivered',
  delivery_failed: 'Attempted',
  in_transit: 'In Transit',
  out_for_collection: 'Packed',
  out_for_delivery: 'Delivering',
  on_hold: 'On Hold',
  pending: 'Packed',
  ready_for_collection: 'Packed',
  received_at_depot: 'Collected',
  return_to_origin: 'Cancelled',
  returned_to_origin: 'Cancelled',
  scheduled: 'Scheduled',
}

export { externalStatus }
