import React from 'react'
import moment from 'moment'
import { ClockCircleOutlined } from '@ant-design/icons'
import { WHATSAPP_URL, generateWhatsappMessage } from '../../../utils/constants'
import { useTracking } from '../../../hooks/useTracking'

interface HeaderContentProps {
  state: string
  date?: string
}

type ContentTypeStateKeys = {
  title: string
  main: string
  link: string
  note: string
}

const stateToContent: { [key in string]: ContentTypeStateKeys } = {
  onhold: {
    title: 'Your order is',
    main: 'On Hold',
    link: 'Chat with us',
    note: ' for more information',
  },
  cancelled: {
    title: 'Sorry 😢! We couldn’t deliver your order because it was',
    main: 'Cancelled',
    link: 'Chat with us',
    note: ' for more information',
  },
  attempted: {
    title: 'Sorry 😢! We couldn’t deliver. We will try again',
    main: 'Tomorrow',
    link: 'Chat with us',
    note: ' if you want to reschedule',
  },
}
const HeaderContent = ({ state, date }: HeaderContentProps) => {
  const { orderDetails } = useTracking()
  if (!orderDetails?.id) {
    return (
      <>
        <div className="info-title">Enter the order ID to</div>
        <div className="info-main sun">Track your order</div>
        <div className="info-note">
          and see the details of your delivery/collection
        </div>
        <div className="time-disclaimer"></div>
      </>
    )
  }
  function formattedDate(date: string, state?: string) {
    const momentDate = moment(date)
    if (state === 'Delivering') {
      return moment().isSame(date, 'day')
        ? momentDate.format('dddd [the] Do [of] MMMM [before] hh:mm a')
        : momentDate.format('[before] hh:mm a')
    }
    return momentDate.format('dddd [the] Do [of] MMMM [before] hh:mm a')
  }

  if (
    orderDetails?.serviceKind === 'partner_return' &&
    date &&
    !['On Hold', 'Cancelled', 'Attempted', 'Delivered', 'Delivering'].includes(
      state
    )
  ) {
    const willBeTomorrow =
      orderDetails?.origin?.zone?.parentGroup !== null &&
      orderDetails?.origin?.zone?.parentGroup !== 'Scheduled Routes'

    const displayDate = willBeTomorrow
      ? moment(date)
          .set({ hour: 22, minute: 0 })
          .add(1, 'day')
          .format('dddd [the] Do [of] MMMM [before] hh:mm a')
      : moment(date).add(5, 'day').format('[until] dddd [the] Do [of] MMMM')

    return (
      <>
        <div className="info-title">Expected</div>
        <div className="info-main sun">collection</div>
        <div className="info-note">{displayDate}</div>
        <div className="time-disclaimer">
          <ClockCircleOutlined /> Current time (GMT
          {moment(date).format('Z')})
        </div>
      </>
    )
  }
  if (state === 'Delivered' && date) {
    return (
      <>
        <div className="info-title">Delivered</div>
        <div className="info-main sky">
          {moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: 'dddd',
            nextWeek: 'dddd',
            sameElse: 'dddd',
          })}
        </div>
        <div className="info-note">{formattedDate(date)}</div>
        <div className="time-disclaimer">
          <ClockCircleOutlined /> Current time (GMT
          {moment(date).format('Z')})
        </div>
      </>
    )
  }
  if (['On Hold', 'Cancelled', 'Attempted'].includes(state)) {
    return (
      <>
        <div className="info-title">
          {stateToContent[state.replace(/\s/g, '').toLowerCase()].title}
        </div>
        <div className="info-main fire">
          {stateToContent[state.replace(/\s/g, '').toLowerCase()].main}
        </div>
        <div className="info-note">
          <a
            href={`${WHATSAPP_URL}${generateWhatsappMessage(
              orderDetails?.id,
              'help'
            )}`}
          >
            {stateToContent[state.replace(/\s/g, '').toLowerCase()].link}
          </a>
          {stateToContent[state.replace(/\s/g, '').toLowerCase()].note}
        </div>
      </>
    )
  }
  if (['Delivering'].includes(state) && date) {
    return (
      <>
        <div className="info-title">Expected to arrive</div>
        <div className="info-main sun">Today</div>
        <div className="info-note">{formattedDate(date, state)}</div>
        <div className="time-disclaimer">
          <ClockCircleOutlined /> Current time (GMT
          {moment(date).format('Z')})
        </div>
      </>
    )
  }
  if (date) {
    return (
      <>
        <div className="info-title">Expected to arrive</div>
        <div className="info-main sun">
          {moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: 'dddd',
            nextWeek: 'dddd',
            sameElse: 'dddd',
          })}
        </div>
        <div className="info-note">{formattedDate(date, state)}</div>
        <div className="time-disclaimer">
          <ClockCircleOutlined /> Current time (GMT
          {moment(date).format('Z')})
        </div>
      </>
    )
  } else {
    return <></>
  }
}

export default HeaderContent
