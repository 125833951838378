export const WHATSAPP_URL =
  'https://api.whatsapp.com/send/?phone=%2B97145490493&text='

export const generateWhatsappMessage = (orderId, context) => {
  switch (context) {
    case 'address':
      return encodeURIComponent(
        `Hi! Please change the address for the order ${orderId} to the following:`
      )
    case 'help':
      return encodeURIComponent(
        `Hi! Could you please help me with the order ${orderId}`
      )

    default:
      break
  }
}
