import React, { useState } from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import UpdateAddressModal from './update-address-modal'
import { Destination, OrderState, ServiceKind } from '../../../types/Order'
import { useAnalytics } from '../../../hooks/useAnalytics'
import { EventMapper } from '../utils'
import UpdateAddressUnavailable from './update-address-unavailable'

const StyledButton = styled(Button)`
  margin-top: 8px;
`

type Props = {
  address?: Destination
  orderUuid: string
  state?: OrderState
  serviceKind?: ServiceKind
  orderId: number
  addressType: 'origin' | 'destination'
  hide?: boolean
}

function getCanUpdateAddress({
  state,
  serviceKind,
  orderUuid,
}: {
  state?: OrderState
  serviceKind?: ServiceKind
  orderUuid?: string
}) {
  if (!state || !serviceKind || !orderUuid) {
    return false
  }

  if (
    (
      ['partner_same_day', 'partner_next_day', 'partner_4hr'] as ServiceKind[]
    ).includes(serviceKind)
  ) {
    const enabledStates: OrderState[] = [
      'pending',
      'ready_for_collection',
      'collection_failed',
      'out_for_collection',
      'collected',
      'received_at_depot',
      'delivery_failed',
      'collection_failed',
      'at_depot',
      'in_transit',
      'on_hold',
      'scheduled',
    ]

    return enabledStates.includes(state)
  }

  if (serviceKind === 'partner_return') {
    const enabledStates: OrderState[] = [
      'pending',
      'ready_for_collection',
      'collection_failed',
    ]

    return enabledStates.includes(state)
  }

  return false
}

export default function UpdateAddressBtn(props: Props) {
  const [isAddressUpdateOpen, setIsAddressUpdateOpen] = useState(false)
  const [isUnavailableMsgOpen, setIsUnavailableMsgOpen] = useState(false)
  const { track } = useAnalytics()

  if (!props.address || props.hide) {
    return null
  }

  const canUpdateAddress = getCanUpdateAddress({
    state: props.state,
    serviceKind: props.serviceKind,
    orderUuid: props.orderUuid,
  })

  const handleTrackEvent = (event: string) => {
    track(`Website Tracking Page ${EventMapper[event].event}`, {
      action: EventMapper[event].action,
      category: EventMapper[event].category,
      product: 'TrackOrder',
      orderId: String(props.orderId),
    })
  }

  const handleClick = () => {
    if (canUpdateAddress) {
      handleTrackEvent('change address')
      setIsAddressUpdateOpen(true)
    } else {
      handleTrackEvent('change address unavailable')
      setIsUnavailableMsgOpen(true)
    }
  }

  return (
    <>
      <UpdateAddressModal
        orderUuid={props.orderUuid}
        isOpen={isAddressUpdateOpen}
        setIsOpen={setIsAddressUpdateOpen}
        destination={props.address}
        addressType={props.addressType}
      />
      <UpdateAddressUnavailable
        isOpen={isUnavailableMsgOpen}
        onClose={() => setIsUnavailableMsgOpen(false)}
      />
      <StyledButton
        onClick={handleClick}
        type="default"
        block
        data-testid="update-address-btn"
      >
        Update address
      </StyledButton>
    </>
  )
}
