import { useState } from 'react'
import codes from '../utils/dialingCode.json'

function useGetDiallingCodes() {
  const [diallingCodes] = useState(
    codes.map((code) => ({
      country: code.name,
      value: code.dialCode,
      countryCode: code.code,
    }))
  )
  return { diallingCodes }
}

export default useGetDiallingCodes
