import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
} from 'react'

type OrderContextType = {
  order: any
  setOrder: (order: any) => void
  setPhone: (phone: { number?: string; dialingCode?: string }) => void
  phone: { number?: string; dialingCode?: string }
}
export const OrderContext = createContext<OrderContextType>({
  order: null,
  setOrder: () => {},
  setPhone: () => {},
  phone: { number: undefined, dialingCode: undefined },
})

export const useOrder = () => useContext(OrderContext)

// centralize the state of the order
// uses the useGetOrder and useOrderHistory hooks
// exposes the methods to handle the order state fetch and update

export const OrderProvider = ({ children }: PropsWithChildren) => {
  const [order, setOrder] = useState(null)
  const [phone, setPhone] = useState<{
    number?: string
    dialingCode?: string
  }>({ number: undefined, dialingCode: undefined })

  return (
    <OrderContext.Provider value={{ order, setOrder, setPhone, phone }}>
      {children}
    </OrderContext.Provider>
  )
}
