/* istanbul ignore file */

import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import QuiqupJS from '@quiqupltd/quiqupjs'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { normalize } from 'styled-normalize'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import loadEnv from './configEnv'
import './styles/index.css'
import './styles/main.scss'

import theme from './theme'
import './App.less'
import { AnalyticsProvider } from './hooks/useAnalytics'
import { EnvVarsProvider } from './hooks/useEnvVars'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font-family: RelativeBook, Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    background-color: ${(p) => p.theme.midnight50};
  }
`

loadEnv().then((env) => {
  QuiqupJS.config.update({
    api: {
      API_BASE_URL: env.API_URL,
    },
  })

  const container = document.getElementById('root')!

  // Create a root.
  const root = ReactDOMClient.createRoot(container)

  root.render(
    <React.StrictMode>
      <EnvVarsProvider envVars={env}>
        <AnalyticsProvider writeKey={env.ANALYTICS_TOKEN}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <GlobalStyle />
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </AnalyticsProvider>
      </EnvVarsProvider>
    </React.StrictMode>
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
