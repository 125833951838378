import React from 'react'
import styled from 'styled-components'

import QuiqupLogo from '../../assets/quiqup-logo.png'

const Container = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 0.87rem;
`

function PoweredBy() {
  return (
    <Container>
      Delivered by{' '}
      <a href="https://www.quiqup.com/" data-testid="site-link">
        <img alt="Quiqup" src={QuiqupLogo} width={160} />
      </a>
    </Container>
  )
}

export default PoweredBy
