import React, { useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  Container,
  CardContainer,
  Content,
  PoweredBy,
  Card,
} from '../../v2/styles'
import { useParams } from 'react-router-dom'
import { ConditionalCardWrapper } from '../order-details/utils'
import Logo from '../order-details/logo'
import Header from '../order-details/header/header'
import Spinner from '../order-details/loader'
import NotFoundCard from '../not-found/not-found-card'
import CourierInfo from '../order-details/courier-info'
import ShipmentInfo from '../order-details/shipment-info'
import QuiqupLogo from '../../assets/quiqup-logo.png'
import DestinationInfo from '../order-details/destination-info'
import CanLeaveAtDoor from '../order-details/can-leave-at-door'
import PaymentInfo from '../order-details/payment-info'
import OtpInfo from '../order-details/otp-info'
import CustomerServiceInfo from '../order-details/customer-service-info'
import OriginInfo from '../order-details/origin-info'
import AuthenticateForm from '../order-details/authenticate-form'
import { useTracking } from '../../hooks/useTracking'

export default function OrderDetailsPage() {
  const { uuid }: { uuid?: string } = useParams()
  const {
    orderDetails,
    liveOrder,
    hasMap,
    isLoadingOrder,
    isOrderError,
    getOrder,
  } = useTracking()

  useEffect(() => {
    if (uuid) {
      getOrder({ orderId: uuid, replace: true, triggerAlert: false })
    }
  }, [getOrder, uuid])

  if ((isLoadingOrder && !orderDetails) || (!orderDetails && !isOrderError)) {
    return <Spinner />
  }

  if (isOrderError && !orderDetails) {
    return (
      <NotFoundCard
        data-testid="invalid-order"
        title="We can’t seem to find this shipment in our system."
        subTitle="Please contact your shipper."
      />
    )
  }

  return (
    <Container data-testid="order-info">
      <ConditionalCardWrapper>
        <CardContainer mapExist={hasMap}>
          <Card mapExist={hasMap}>
            <Logo />
            <Header />
            <Content
              mapExist={hasMap}
              courierExist={Boolean(liveOrder.quiqee?.name)}
            >
              <AnimatePresence mode="popLayout">
                <AuthenticateForm key="authentication-form" />
                <CourierInfo key="courier-info" />
                <ShipmentInfo key="shipment-info" />
                <OriginInfo key="origin-info" />
                <DestinationInfo key="destination-info" />
                <CanLeaveAtDoor key="can-leave-at-door" />
                <PaymentInfo key="payment-info" />
                <OtpInfo key="otp-info" />
                <CustomerServiceInfo key="customer-service-info" />
              </AnimatePresence>
            </Content>
            <PoweredBy>
              <div className="android-bottom-fix"></div>
              Powered by <img src={QuiqupLogo} height={30} alt="Quiqup" />
            </PoweredBy>
          </Card>
        </CardContainer>
      </ConditionalCardWrapper>
    </Container>
  )
}
