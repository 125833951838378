const theme = {
  slate50: '#f3f2f4',
  slate100: '#dcd9e0',
  slate400: '#968DA4',
  slate500: '#7F7490',
  cornFlower500: '#6C65F7',
  midnight50: '#f1f1fe',
  midnight100: '#C3B9D2',
  midnight500: '#23005B',
  midnight800: '#10002A',
  sunflower500: '#FFD100',
  peach500: '#FF847C',
  ghost600: '#6B6D72',
  ghost700: '#414245',
  sky500: '#46E1F5',
  minBreakpoints: {
    mobile: '429px',
    tablet: '769px',
    laptop: '1025px',
    desktop: '1201px',
  },
  maxBreakpoints: {
    mobile: '428px',
    tablet: '768px',
    laptop: '1024px',
    desktop: '1200px',
  },
}

export default theme
