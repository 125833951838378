import styled from 'styled-components'
import { Button } from 'antd'
import theme from '../../theme'
interface StepProps {
  readonly isActive: boolean
}

interface CardProps {
  readonly mapExist: boolean
  readonly courierExist?: boolean
}
interface OrderStatesProps {
  readonly numberOfChanges: number
  readonly progressWidth: string
  readonly noAddedWidth: string
}

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    display: flex;
    height: 100vh;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
  }
`

export const CardContainer = styled.div<CardProps>`
  @media (min-width: ${theme.minBreakpoints.tablet}) {
    width: ${(props) => (props.mapExist ? '429px' : '429px')};
    height: ${(props) => (props.mapExist ? '100%' : '100%')};
    box-shadow: 2px 2px 10px 2px rgb(197 197 197 / 75%);
  }

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 10px 2px rgb(197 197 197 / 75%);
  }

  position: relative;
`

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  background: #f3f2f4;
  margin: 0 auto;

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    width: ${(props) => (props.mapExist ? '429px' : '429px')};
    height: ${(props) => (props.mapExist ? '100%' : '100%')};
    box-shadow: 2px 2px 10px 2px rgb(197 197 197 / 75%);
  }

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 10px 2px rgb(197 197 197 / 75%);
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const TopLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 15px 0px 25px 0px;
  & > img,
  svg {
    max-width: 245px;
    max-height: 80px;
  }
`

export const HeaderInfo = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background: ${theme.midnight500};
  padding: ${(props) =>
    props.mapExist ? '15px 100px 60px 100px' : '15px 55px 60px 55px'};
  min-height: 200px;
  max-height: 310px;
  text-align: center;
  position: relative;

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    padding: 15px 30px 60px 30px;
  }

  & > .info-icon {
    position: absolute;
    border-radius: 99999px;
    background-color: ${theme.midnight500};
    padding: 9px;
    padding-bottom: 7px;
    top: -19px;
    color: white;
    font-size: 24px;
  }

  & > .info-title {
    font-weight: 100;
    color: white;
    font-size: 14px;
    line-height: 14px;
    padding-top: 20px;
  }

  & > .info-main {
    font-weight: 100;
    color: ${theme.sunflower500};
    font-size: 40px;
    line-height: 40px;
  }

  a {
    font-weight: 100;
    color: white;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none !important;
  }

  & > .info-note {
    font-weight: 100;
    color: white;
    font-size: 14px;
    line-height: 14px;
  }

  & > .time-disclaimer {
    font-weight: 100;
    color: white;
    font-size: 10px;
    line-height: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 4px;
    opacity: 0.6;
  }

  & > .sky {
    color: ${theme.sky500};
  }
  & > .sun {
    color: ${theme.sunflower500};
  }
  & > .fire {
    color: ${theme.peach500};
  }

  & > .info-report {
    font-weight: 100;
    color: ${theme.peach500};
    font-size: 12px;
    line-height: 12px;
  }
`

export const StatusInfo = styled.div`
  z-index: 1;
  position: absolute;
  height: 120px;
  background-color: #fff;
  width: calc(100% - 38px);
  bottom: 0;
  transform: translateY(calc(100% - 40px));
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

  .status-title {
    margin: 11px 0px 0px 24px;
  }
`

export const OrderStatesWrapper = styled.div`
  position: relative;
  overflow-x: auto;
  height: 100%;
  padding: 0px 14px 0px 14px;
  margin: 0px 12px 0px 12px;
`

export const OrderStates = styled.div<OrderStatesProps>`
  margin: 0px 10px 0px 10px;
  align-self: center;
  display: flex;
  margin-top: 50px;
  position: relative;
  min-width: ${(props: OrderStatesProps) =>
    props.numberOfChanges > 4 ? '450px' : '200px'};
  ${(props) =>
    props.numberOfChanges > 1
      ? `justify-content: space-between;


  :before {
    content: '';
    position: absolute;
    background: ${theme.midnight50};
    height: 2px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }`
      : `justify-content: center;`}
  :after {
    content: '';
    position: absolute;
    background: ${theme.cornFlower500};
    height: 2px;
    width: ${(props) => `${props.progressWidth}%`};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
    animation: fillLine 1s linear forwards;
  }

  @keyframes fillLine {
    0% {
    width: ${(props) => props.noAddedWidth};
    }
    30% {
    width: ${(props) => props.noAddedWidth};
    }
    100% {
    width: ${(props) => props.progressWidth};
    }
`

export const StepWrapper = styled.div`
  position: relative;
`

export const StepStyle = styled.div<StepProps>`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.isActive ? theme.cornFlower500 : theme.midnight100};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepsIconContainer = styled.div`
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StepsLabelContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  width: 55px;
  transform: translate(-50%, -50%);
`

export const StepLabel = styled.span<StepProps>`
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  color: ${(props) =>
    props.isActive ? theme.cornFlower500 : theme.midnight100};

  & > .timestamp {
    font-size: 9px;
    line-height: 9px;
  }
`

export const CardTitle = styled.div`
  text-align: left;
  font-size: 10px;
  line-height: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${theme.midnight500};
`

export const Content = styled.div<CardProps>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 19px 12px 19px;
  margin-bottom: 32px;
  gap: 12px;

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    margin-top: 80px;
  }

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    margin-top: ${(props) =>
      props.mapExist ? (props.courierExist ? '300px' : '410px') : '80px'};
  }
`

export const ConfirmAddress = styled(Button)`
  margin-top: 14px;
`

export const PoweredBy = styled.div`
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #ffffff;
  bottom: 0px;
  font-size: 12px;
  font-weight: 100;
  width: inherit;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    width: 100%;
  }

  .android-bottom-fix {
    position: fixed;
    color: transparent;
  }
`

export const CardWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;

  @media (max-width: ${theme.maxBreakpoints.tablet}) {
    top: 0;
    width: 100%;
    // max-width: 429px;
    height: 100%;
  }
`

export const MapWrapper = styled.div`
  z-index: 0;
  position: absolute;
  height: 380px;
  background-color: #fff;
  width: 100%;
  bottom: -380px;

  .qu-map {
    height: 100%;
    width: 100%;
  }

  .leaflet-control-zoom {
    display: none;
  }

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    position: fixed;
    right: 0;
    top: 0;
    width: calc(100% - 429px);
    height: 100vh;
  }

  @media (max-width: ${theme.minBreakpoints.mobile}) {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(243, 242, 244, 0),
        rgba(243, 242, 244, 1) 100%
      );
      width: 100%;
      height: 6em;
    }
  }
`

interface MapScrollOverlayProps {
  readonly isMapActive: boolean
}

export const MapScrollOverlay = styled.div<MapScrollOverlayProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  display: ${(p) => (p.isMapActive ? 'none' : 'block')};

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    display: none;
  }
`

export const OtpCard = styled.div`
  background-color: #fff;
  height: 85px;
  border-radius: 6px;
  padding: 11px 19px 15px 19px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  font-weight: 500;

  & > .otp-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 100;
    font-size: 16px;
    line-height: 21px;
    color: ${theme.midnight800};

    & > .otp-value {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1em;
      color: ${theme.midnight800};
    }

    & > .otp-note {
      color: ${theme.slate500};
      font-size: 10px;
      line-height: 21px;

      .info-circle-icon {
        margin-right: 4px;
      }
    }
  }
`
