/* istanbul ignore file */

interface EnvKeys {
  API_URL?: string
  SITE_ENV?: string
  ANALYTICS_TOKEN?: string
  SOCKET_URL?: string
  EXCORE_SOCKET_URL?: string
  CARRIER_MANAGEMENT?: string
  [key: string]: string | undefined
}

const ENV_JSON = '/env.json'
const ENV_VAR_ACCEPTED_VARS: string[] = [
  'API_URL',
  'SITE_ENV',
  'ANALYTICS_TOKEN',
  'SOCKET_URL',
  'EXCORE_URL',
  'EXCORE_SOCKET_URL',
  'CARRIER_MANAGEMENT',
]

const getEnvVars = async (): Promise<EnvKeys> => {
  if (process.env.NODE_ENV === 'production') {
    const resp = await fetch(ENV_JSON)
    return resp.json()
  }

  return new Promise((resolve) => resolve(process.env))
}

const loadEnv = async (): Promise<EnvKeys> => {
  const env = await getEnvVars()
  let newVars: EnvKeys = {}
  ENV_VAR_ACCEPTED_VARS.forEach((key) => {
    const val = env[key] || env[`REACT_APP_${key}`]
    if (val) {
      newVars[key] = env[key] || env[`REACT_APP_${key}`]
    }
  })
  return newVars
}

export default loadEnv
