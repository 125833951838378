import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import packageJson from '../../package.json'

// All you need to know about TRACKING
// https://coda.io/d/Tracking-Plan_dtFayJzhpYT/Best-Practice_sucqY#_lutES

type EventTrackProperties = {
  action: string | number | boolean // Action Value: Direct value of the event we are tracking, eg: for a toggle its true or false, for an input field its the text value, etc.. (Can be seen as the regular Value, but with generic type)
  category: string // Category: events can be categorized by context, ex: Zone Restrictions, Live Jobs, Orders’ Listing, ..
  product: string // Product: The app sending the event, QD, OMS, Dispatch etc..
  orderId?: string
  phone?: string
  errors?: string
}

const AnalyticsContext = createContext<Analytics | undefined>(undefined)

export function AnalyticsProvider({
  writeKey,
  children,
}: PropsWithChildren<{ writeKey?: string }>) {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)

  useEffect(() => {
    if (writeKey) {
      const loadAnalytics = async () => {
        let [response] = await AnalyticsBrowser.load({ writeKey })
        setAnalytics(response)
      }
      loadAnalytics()
    }
  }, [writeKey])

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export function useAnalytics() {
  const analytics = useContext(AnalyticsContext)

  const identify = useCallback(
    (userId: string, traits: Record<string, string> = {}) => {
      analytics?.identify(userId, {
        ...traits,
        app_version: packageJson.version,
      })
    },
    [analytics]
  )

  const page = useCallback(
    (title: string, properties: Record<string, string> = {}) => {
      analytics?.page(title, {
        ...properties,
        app_version: packageJson.version,
      })
    },
    [analytics]
  )

  const track = useCallback(
    (
      evetName: string,
      { category, product, orderId, phone, errors }: EventTrackProperties
    ) => {
      analytics?.track(evetName, {
        category,
        product,
        errors,
        orderId,
        phone,
        app_version: packageJson.version,
      })
    },
    [analytics]
  )

  return {
    analytics,
    identify,
    page,
    track,
  }
}
