
import { useEffect, useState } from 'react';

interface UseScrollPositionProps {
  isAtStart: boolean;
  isAtEnd: boolean;
}

export default function useScrollPosition(ref: React.RefObject<HTMLElement>, enabled: boolean): UseScrollPositionProps {
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(true);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const handleScroll = () => {
      const { current } = ref;

      if (current) {
        const atStart = current.scrollTop <= 3;
        const atEnd =
          current.scrollTop + current.clientHeight >= current.scrollHeight - 3;
        
        setIsAtStart(atStart);
        setIsAtEnd(atEnd);
      }
    };

    const scrollElement = ref.current;

    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);

      // Cleanup event listener on component unmount
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [ref, enabled]);

  return { isAtStart, isAtEnd };
};