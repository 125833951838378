import React from 'react'
import { ReactComponent as ItemIcon } from '../../../assets/icons/item.svg'
import { ReactComponent as ParcelIcon } from '../../../assets/icons/parcel.svg'
import { ReactComponent as VanIcon } from '../../../assets/icons/van.svg'
import { ReactComponent as HouseIcon } from '../../../assets/icons/house.svg'
import { ReactComponent as CarIcon } from '../../../assets/icons/car.svg'
import { ReactComponent as ChecklistIcon } from '../../../assets/icons/checklist.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'
import { ReactComponent as ReturnsIcon } from '../../../assets/icons/returns.svg'
import { ReactComponent as HelpIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as ActiveItemIcon } from '../../../assets/icons/item-active.svg'
import { ReactComponent as ActiveParcelIcon } from '../../../assets/icons/parcel-active.svg'
import { ReactComponent as ActiveVanIcon } from '../../../assets/icons/van-active.svg'
import { ReactComponent as ActiveHouseIcon } from '../../../assets/icons/house-active.svg'
import { ReactComponent as ActiveCarIcon } from '../../../assets/icons/car-active.svg'
import { ReactComponent as ActiveChecklistIcon } from '../../../assets/icons/checklist-active.svg'
import { ReactComponent as ActiveCalendarIcon } from '../../../assets/icons/calendar-active.svg'
import { ReactComponent as ActiveReturnsIcon } from '../../../assets/icons/returns-active.svg'
import { ReactComponent as ActiveHelpIcon } from '../../../assets/icons/help-active.svg'

interface StateIconProps {
  icon?: string
  active: Boolean
}
const StateIcon = ({ icon, active }: StateIconProps) => {
  if (active) {
    if (icon === 'Packed') {
      return <ActiveItemIcon width={34} height={34} />
    }
    if (icon === 'Collected') {
      return <ActiveParcelIcon width={34} height={34} />
    }
    if (icon === 'In Transit') {
      return <ActiveVanIcon width={34} height={34} />
    }
    if (icon === 'Delivering') {
      return <ActiveCarIcon width={34} height={34} />
    }
    if (icon === 'Attempted') {
      return <ActiveChecklistIcon width={34} height={34} />
    }
    if (icon === 'On Hold') {
      return <ActiveHelpIcon width={34} height={34} />
    }
    if (icon === 'Scheduled') {
      return <ActiveCalendarIcon width={34} height={34} />
    }
    if (icon === 'Delivered') {
      return <ActiveHouseIcon width={34} height={34} />
    }
    if (icon === 'Cancelled') {
      return <ActiveReturnsIcon width={34} height={34} />
    } else {
      return <ActiveCalendarIcon width={34} height={34} />
    }
  } else {
    if (icon === 'Packed') {
      return <ItemIcon width={34} height={34} />
    }
    if (icon === 'Collected') {
      return <ParcelIcon width={34} height={34} />
    }
    if (icon === 'In Transit') {
      return <VanIcon width={34} height={34} />
    }
    if (icon === 'Delivering') {
      return <CarIcon width={34} height={34} />
    }
    if (icon === 'Attempted') {
      return <ChecklistIcon width={34} height={34} />
    }
    if (icon === 'On Hold') {
      return <HelpIcon width={34} height={34} />
    }
    if (icon === 'Scheduled') {
      return <CalendarIcon width={34} height={34} />
    }
    if (icon === 'Delivered') {
      return <HouseIcon width={34} height={34} />
    }
    if (icon === 'Cancelled') {
      return <ReturnsIcon width={34} height={34} />
    } else {
      return <CalendarIcon width={34} height={34} />
    }
  }
}
export default StateIcon
