import { useEffect, useState } from "react"
import { Socket } from "phoenix"
import CaseNormaliser from '@quiqupltd/quiqupjs/lib/services/case-normaliser'
import { createFeature } from '@quiqupltd/QuReactSDK/lib/components/qu-map/qu-map-abstract/qu-map-abstract.helpers'
import get from 'lodash/get'
import { useEnvVars } from "./useEnvVars"

type UseTrackingSocketProps = {
  token?: string
  missionId?: string
  shouldConnect: boolean
}

const camelNormaliser = new CaseNormaliser(CaseNormaliser.camelCase)

const FEATURE_OPTIONS = {
  markerText: 'Your order',
  isTrackOrder: true,
}

export default function useTrackingSocket(props: UseTrackingSocketProps) {
  const { env } = useEnvVars()
  const [trackingSocket, setTrackingSocket] = useState<Socket | null>(null)
  const [liveOrder, setLiveOrder] = useState<any>({
    features: [],
  })
  const [mapCenter, setMapCenter] = useState<number[] | undefined>()
  const [invalidToken, setInvalidToken] = useState(false)

  const liveOrderFeaturesLength = liveOrder.features.length

  useEffect(() => {
    if (!props.token || !env.EXCORE_SOCKET_URL || !env.SOCKET_URL || !props.shouldConnect) return

    const socketUrl = props.missionId ? env.EXCORE_SOCKET_URL : env.SOCKET_URL
    const socket = new Socket(socketUrl, {
      params: {
        tracking_token: props.token,
        mission_id: props.missionId,
      },
    })
    setTrackingSocket(socket)
    socket.connect()

    socket.onOpen(() => {
      if (liveOrderFeaturesLength > 0) {
        const waypointChannel = socket.channel(`tracking:waypoints:${props.token}`)
        waypointChannel.join()

        waypointChannel.on('update', (updatedData: any) => {
          if (invalidToken) {
            setInvalidToken(false)
          }

          setLiveOrder((prevLiveOrder: any) => {
            const data = camelNormaliser.call(updatedData)
            const availableQuiqee = get(prevLiveOrder, 'quiqee')
      
            // dont update the state if the courier position is the same
            const courier = prevLiveOrder.features?.find(
              (feature: { properties: { type: string } }) =>
                feature.properties.type === 'courier'
            )
      
            const isCourierStopped =
              courier &&
              courier.geometry.coordinates[0] === data.coords[0] &&
              courier.geometry.coordinates[1] === data.coords[1]
      
            if (!Boolean(isCourierStopped)) {
              // QuMap - Remove Quiqee from the Array to update its location/state
              const features = prevLiveOrder.features?.filter(
                (feature: { properties: { type: string } }) =>
                  feature.properties.type !== 'courier'
              )
      
              // QuMap - Update the features array with new Quiqee location/state
              if (availableQuiqee) {
                features?.push({
                  ...createFeature(
                    {
                      coords: data.coords,
                      fullname: get(prevLiveOrder, 'quiqee.name', ''),
                      transportMode: get(prevLiveOrder, 'quiqee.transportMode'),
                      jobState: get(prevLiveOrder, 'jobState'),
                    },
                    'QUIQEE',
                    FEATURE_OPTIONS
                  ),
                })
              }
      
              return {
                ...prevLiveOrder,
                features: features,
                eta: get(data, 'eta.waypointEstimates[0].estimatedMinutes.value'),
              }
            }

            return prevLiveOrder
          })
        })

        waypointChannel.on('error', (value) => {
          setInvalidToken(true)
        })
      } else {
        const missionChannelName = props.missionId ? `tracking:missions:${props.token}` : `tracking:jobs:${props.token}`
        const missionChannel = socket.channel(missionChannelName)
        missionChannel.join()

        missionChannel.on('update', async (updatedData: any) => {
          if (invalidToken) {
            setInvalidToken(false)
          }

          const data = await camelNormaliser.call(updatedData)
          if (data) {
            const { waypoint, numberOfWaypointsAhead } = data
    
            waypoint.jobState = data.state
    
            let features: any[] = []
            features?.push(createFeature(waypoint, 'WAYPOINT', FEATURE_OPTIONS))
    
            const centerLat = parseFloat(get(data, 'region.centerLat'))
            const centerLon = parseFloat(get(data, 'region.centerLon'))
            const mapCenter =
              centerLat && centerLon ? [centerLat, centerLon] : undefined
            const availableCourier = !!get(data, 'courier')
            let quiqee: any
    
            if (availableCourier) {
              quiqee = {
                img: get(data, 'courier.avatar.thumb.url'),
                name: get(data, 'courier.firstname'),
                transportMode: get(data, 'courier.transportMode'),
                phone: get(data, 'courier.mobile'),
                jobState: get(data, 'state'),
              }
    
              if (get(data, 'courier.currentLocation.coords')) {
                features?.push({
                  ...createFeature(
                    {
                      coords: get(data, 'courier.currentLocation.coords'),
                      fullname: get(data, 'courier.firstname'),
                      transportMode: get(data, 'courier.transportMode'),
                      jobState: get(data, 'state'),
                    },
                    'QUIQEE',
                    FEATURE_OPTIONS
                  ),
                })
              }
            } else {
              quiqee = null
            }
    
            if (!availableCourier) {
              features = features?.filter(
                (feature) => feature.properties.type !== 'courier'
              )
            }
    
            setLiveOrder((prevLiveOrder: any) => ({
              ...prevLiveOrder,
              numberOfWaypointsAhead,
              jobState: data.state,
              orderId: data.slug,
              region: data.region,
              kind: data.kind,
              eta: prevLiveOrder.eta,
              quiqee,
              features: [...features],
              waypoint,
            }))
            
            setMapCenter(mapCenter)
          }
        })
        
        missionChannel.on('error', (value) => {
          setInvalidToken(true)
        })
      }
    })

    return () => {
      if (socket.isConnected()) {
        socket.disconnect()
      }
    }
  }, [props.token, props.missionId, env.EXCORE_SOCKET_URL, env.SOCKET_URL, props.shouldConnect, liveOrderFeaturesLength, invalidToken])

  return { liveOrder, mapCenter, invalidToken, trackingSocket }
}