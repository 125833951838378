import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Form, Input, notification, Select } from 'antd'
import ApiClient from '@quiqupltd/quiqupjs/lib/network/api-client'
import { useMutation } from 'react-query'
import Logo from '../logo'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import useWindowSize from '../../../hooks/useWindowSize'
import UpdateAddressMap from './update-address-map'
import { Destination } from '../../../types/Order'
import { useEnvVars } from '../../../hooks/useEnvVars'
import useScrollPosition from '../../../hooks/useScrollPosition'
import {
  AddressFieldsTitle,
  AddressForm,
  AddressFormCard,
  AddressFormContainer,
  BottomActions,
  CloseButton,
  CloseDrawerButton,
  ConfirmAddressButton,
  Container,
  Content,
  FieldsWrapper,
  FormMask,
  HeaderContainer,
  HeaderIcon,
  HeaderSubtitle,
  HeaderTitle,
  LogoContainer,
  MapContainer,
} from './update-address-modal-styled'
import { useTracking } from '../../../hooks/useTracking'
import UAECities from '../../../utils/uaeCities.json'

const initialZoom = 15
const { Option: SelectOption } = Select

interface Option {
  label: string
  value: string
  disabled: boolean
}

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  destination: Destination
  orderUuid: string
  addressType: 'origin' | 'destination'
}
export default function UpdateAddressModal(props: Props) {
  const { getOrder } = useTracking()
  const address = props.destination.address
  const { env } = useEnvVars()
  const [form] = Form.useForm()
  const { width } = useWindowSize()
  const [pinConfirmed, setPinConfirmed] = useState(false)
  const [coords, setCoords] = useState(address.coordinates)
  const [hasDragged, setHasDragged] = useState(false)
  const [mapCenter, setMapCenter] = useState(address.coordinates)
  const [mapZoom, setMapZoom] = useState(initialZoom)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { isAtEnd, isAtStart } = useScrollPosition(scrollRef, pinConfirmed)
  const [api, contextHolder] = notification.useNotification()

  const openErrorNotification = () => {
    api.error({
      message: 'Something went wrong',
      description: 'We could not update the address, please try again later.',
    })
  }

  function createOptions(strings: string[]): Option[] {
    return strings.map((string) => ({
      label: string,
      value: string,
      disabled: false,
    }))
  }

  const mutation = useMutation({
    mutationFn: (newAddress: any) => {
      return ApiClient.put({
        path: `${env.EXCORE_URL}/customers/orders/${props.orderUuid}`,
        data: {
          address1: newAddress.address1,
          address2: newAddress.address2,
          town: newAddress.town,
          country: newAddress.country,
          apartment_number: newAddress.apartmentNumber,
          coordinates: {
            lat: String(newAddress.coordinates.lat),
            lng: String(newAddress.coordinates.lng),
          },
          waypoint_type: props.addressType,
        },
      })
    },
    onSuccess: () => {
      getOrder({ orderId: props.orderUuid, replace: true, triggerAlert: false })
      props.setIsOpen(false)
    },
    onError: () => {
      openErrorNotification()
    },
  })
  const handleConfirmAddress = () => {
    if (!pinConfirmed) {
      setPinConfirmed(true)

      return
    }

    const values = form.getFieldsValue()
    const newCoords =
      coords.lat !== 0 && coords.lng !== 0
        ? coords
        : props.destination?.address.coordinates

    mutation.mutate({
      address1: values.address1,
      address2: values.address2,
      town: values.town,
      country: values.country,
      apartmentNumber: values.apartmentNumber,
      coordinates: newCoords,
    })
  }

  useEffect(() => {
    if (!hasDragged) {
      const updatedCoords = {
        lat: address.coordinates.lat,
        lng: address.coordinates.lng,
      }
      setCoords(updatedCoords)
      setMapCenter(updatedCoords)
    }
  }, [address.coordinates.lat, address.coordinates.lng, hasDragged])

  const formInitialValues = {
    address1: address?.address1,
    address2: address?.address2,
    apartmentNumber: address?.apartmentNumber,
    town: address?.town,
    country: address?.country,
  }

  return (
    <Drawer
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      placement="bottom"
      height="100%"
      closable={false}
      bodyStyle={{
        padding: 0,
        backgroundColor: 'transparent',
        background: 'transparent',
      }}
      contentWrapperStyle={{
        maxWidth: width < 769 ? '100%' : '429px',
        margin: '0 auto',
        backgroundColor: 'transparent',
        background: 'transparent',
      }}
      afterOpenChange={(open) => {
        if (!open) {
          setPinConfirmed(false)
          form.resetFields()
          setCoords(address.coordinates)
          setMapCenter(address.coordinates)
          setMapZoom(initialZoom)
          setHasDragged(false)

          return
        }

        form.resetFields()
        setCoords(address.coordinates)
        setMapCenter(address.coordinates)
      }}
    >
      {contextHolder}
      <Container>
        <CloseButton
          onClick={() => props.setIsOpen(false)}
          type="default"
          icon={<CloseOutlined />}
        />
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <HeaderContainer>
          <HeaderIcon />
          <HeaderTitle>Update Address</HeaderTitle>
          <HeaderSubtitle>
            Move the pin to the exact location for accurate delivery
          </HeaderSubtitle>
        </HeaderContainer>
        <Content>
          <MapContainer>
            {props.isOpen ? (
              <UpdateAddressMap
                zoom={mapZoom}
                center={mapCenter}
                onMoveEnd={(c) => setCoords(c)}
                onZoomEnd={(z) => {
                  setMapZoom(z)
                }}
                deliveryCoords={coords}
              />
            ) : null}
            <FormMask
              onClick={() => setPinConfirmed(false)}
              active={pinConfirmed}
            />
            <AddressFormContainer pinConfirmed={pinConfirmed}>
              <AddressFormCard pinConfirmed={pinConfirmed}>
                {props.isOpen ? (
                  <AddressForm
                    name="address-confirmation-form"
                    onFinish={handleConfirmAddress}
                    autoComplete="on"
                    layout="vertical"
                    form={form}
                    initialValues={formInitialValues}
                  >
                    <AddressFieldsTitle
                      withShadow={pinConfirmed && !isAtStart}
                      pinConfirmed={pinConfirmed}
                    >
                      <div>
                        📍{' '}
                        {props.addressType === 'origin'
                          ? 'Collection'
                          : 'Delivery'}{' '}
                        address
                      </div>
                      {pinConfirmed ? (
                        <CloseDrawerButton
                          size="small"
                          icon={<DownOutlined />}
                          onClick={() => setPinConfirmed(false)}
                          type="text"
                        />
                      ) : null}
                    </AddressFieldsTitle>
                    <FieldsWrapper ref={scrollRef}>
                      <Form.Item
                        name="address1"
                        label={pinConfirmed ? 'Address line 1' : undefined}
                        rules={[
                          {
                            required: true,
                            message: `Please enter the ${
                              pinConfirmed
                                ? 'address line 1'
                                : 'delivery address'
                            }`,
                          },
                        ]}
                      >
                        <Input
                          placeholder={
                            pinConfirmed
                              ? 'Address line 1'
                              : 'Full delivery address'
                          }
                          name="address1"
                          allowClear
                          autoComplete="address-line1"
                        />
                      </Form.Item>
                      {pinConfirmed ? (
                        <>
                          <Form.Item
                            name="address2"
                            label="Address line 2"
                            rules={[
                              {
                                required: true,
                                message: `Please enter the ${
                                  pinConfirmed
                                    ? 'address line 2'
                                    : 'delivery address 2'
                                }`,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Address line 2"
                              name="address2"
                              allowClear
                              autoComplete="address-line2"
                            />
                          </Form.Item>
                          <Form.Item
                            name="apartmentNumber"
                            label="Apartment / villa number"
                            rules={[
                              {
                                required: true,
                                message:
                                  'Please enter the apartment / villa number',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Apartment / villa number"
                              name="apartmentNumber"
                              allowClear
                              autoComplete="street-number"
                            />
                          </Form.Item>
                          <Form.Item
                            name="town"
                            label="Emirate"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter the emirate',
                              },
                            ]}
                          >
                            <Select placeholder="Emirate" allowClear>
                              {createOptions(UAECities).map((city) => (
                                <SelectOption
                                  key={city.value}
                                  value={city.value}
                                  data-testid={`${city.value}`}
                                >
                                  {city.label}
                                </SelectOption>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter the country',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Country"
                              name="country"
                              allowClear
                              autoComplete="country"
                              disabled
                            />
                          </Form.Item>
                        </>
                      ) : null}
                    </FieldsWrapper>
                    <BottomActions
                      withShadow={pinConfirmed && !isAtEnd}
                      pinConfirmed={pinConfirmed}
                    >
                      <ConfirmAddressButton
                        type="primary"
                        htmlType="submit"
                        loading={mutation.isLoading}
                        disabled={mutation.isLoading}
                      >
                        {pinConfirmed
                          ? 'Confirm address details'
                          : 'Confirm pin'}
                      </ConfirmAddressButton>
                    </BottomActions>
                  </AddressForm>
                ) : null}
              </AddressFormCard>
            </AddressFormContainer>
          </MapContainer>
        </Content>
      </Container>
    </Drawer>
  )
}
