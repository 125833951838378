import React, { useEffect, useRef, useState } from 'react'
import {
  OrderStates,
  OrderStatesWrapper,
  StepLabel,
  StepStyle,
  StepWrapper,
  StepsIconContainer,
  StepsLabelContainer,
} from '../../../v2/styles'
import { StateChange } from '../../../types/Order'
import { externalStatus } from '../../../lib/status'
import moment from 'moment'
import StateIcon from './state-icon'
import { useTracking } from '../../../hooks/useTracking'

interface FinalStateChange {
  state: string
  date?: string
  active: boolean
}

type Props = {
  uniqueStateChanges: (StateChange | undefined)[]
}
export default function OrderStatesList(props: Props) {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [barWidth, setBarWidth] = useState(0)
  const progressBarRef = useRef<HTMLDivElement | null>(null)
  const allStates: FinalStateChange[] = []
  const { orderDetails } = useTracking()

  useEffect(() => {
    if (progressBarRef.current) {
      setBarWidth(progressBarRef.current.offsetWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressBarRef.current])

  props.uniqueStateChanges
    .slice()
    .reverse()
    .forEach((stateChange) => {
      allStates.push({
        state: externalStatus[stateChange?.toState ?? 0],
        date: moment(stateChange?.occurredAt).isSame(new Date(), 'day')
          ? moment(stateChange?.occurredAt).format('hh:mma')
          : moment(stateChange?.occurredAt).format('Do MMM'),
        active: true,
      })
    })

  orderDetails?.futureStates?.forEach((stateChange) => {
    allStates.push({
      state: stateChange,
      active: false,
    })
  })

  const allStatesActive = allStates.every((state) => state.active)

  let widthToAdd
  if (allStatesActive) {
    widthToAdd = 0
  } else {
    widthToAdd = (barWidth / (allStates.length - 1) / 2 / barWidth) * 100
  }

  const progressWidth = `${
    (100 / (allStates.length - 1)) * (props.uniqueStateChanges.length - 1) +
    widthToAdd
  }%`

  const noAddedWidth = `${
    (100 / (allStates.length - 1)) * (props.uniqueStateChanges.length - 1)
  }%`

  requestAnimationFrame(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 9999999
    }
  })

  return (
    <OrderStatesWrapper ref={scrollRef}>
      <OrderStates
        numberOfChanges={allStates.length}
        progressWidth={progressWidth}
        noAddedWidth={noAddedWidth}
        ref={progressBarRef}
      >
        {allStates.map((stateChange) => (
          <StepWrapper key={stateChange?.state}>
            <StepsIconContainer>
              <StateIcon icon={stateChange.state} active={stateChange.active} />
            </StepsIconContainer>
            <StepStyle isActive={stateChange.active}></StepStyle>
            <StepsLabelContainer>
              <StepLabel isActive={stateChange.active}>
                {stateChange.state}
                {stateChange.date ? (
                  <div className="timestamp">{stateChange.date}</div>
                ) : null}
              </StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </OrderStates>
    </OrderStatesWrapper>
  )
}
