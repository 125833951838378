import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Cascader } from 'antd'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useTracking } from '../../hooks/useTracking'
import useGetDiallingCodes from '../../hooks/useGetDiallingCodes'
import DetailCard from '../common/detail-card'

type FormValues = {
  phone?: string
  dialingCode?: string
}

const FormContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`
type Props = {}

function AuthenticateForm(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { track } = useAnalytics()
  const { isLoadingOrder, getOrder, orderDetails } = useTracking()
  const { diallingCodes } = useGetDiallingCodes()
  const [requested, setRequested] = useState(false)

  const onFinish = async (values: FormValues) => {
    track('Website Tracking Page Track Button', {
      action: 'submit',
      category: 'tracking',
      product: 'TrackOrder',
      orderId: String(orderDetails?.id),
      phone:
        values.phone && values.dialingCode
          ? values.dialingCode[0].split('-')[0] + values.phone
          : '',
    })
    getOrder({
      orderId: String(orderDetails?.id),
      phone: values.phone ?? '',
      dialingCode: values.dialingCode
        ? values.dialingCode?.[0].split('-')[0]
        : '',
    })
    setRequested(true)
  }

  useEffect(() => {
    if (!isLoadingOrder) {
      setRequested(false)
    }
  }, [isLoadingOrder])

  if (!orderDetails || orderDetails?.uuid) {
    return null
  }

  return (
    <DetailCard
      ref={ref}
      title={
        <>
          ℹ️ See more about your{' '}
          {orderDetails?.serviceKind === 'partner_return'
            ? 'collection'
            : 'delivery'}
        </>
      }
      note="Enter the phone number you used to place the order"
    >
      <Form
        name="tracking-form"
        onFinish={onFinish}
        autoComplete="on"
        layout="horizontal"
        size="large"
      >
        <FormContainer>
          <Form.Item
            label=""
            name="phone"
            rules={[
              () => ({
                required: true,
                message: 'Please enter your phone number.',
              }),
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    (String(value).length >= 6 && String(value).length <= 14)
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('Phone must be between 6 to 14')
                  )
                },
              }),
              () => ({
                validator(_, value) {
                  const numbersOnlyRegex = new RegExp('^[0-9]*$')
                  if (!value || numbersOnlyRegex.test(String(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('Phone must contain numbers only')
                  )
                },
              }),
            ]}
          >
            <Input
              data-testid="phone-no-input"
              type="tel"
              name="quiqPhoneNumber"
              autoComplete="quiq tel-national"
              maxLength={14}
              addonBefore={
                <Form.Item
                  name="dialingCode"
                  noStyle
                  initialValue={['971-AE']}
                  id="quiqup-order-phone-code"
                >
                  <Cascader
                    style={{ width: 80 }}
                    allowClear={false}
                    showSearch={{
                      filter: function filter(inputValue, path) {
                        return path.some(
                          (item) =>
                            (item?.label as string)
                              ?.toLocaleLowerCase()
                              .indexOf(inputValue.toLocaleLowerCase()) > -1
                        )
                      },
                    }}
                    options={diallingCodes.map((code) => ({
                      label: `+${code.value} - ${code.country}`,
                      value: `${code.value}-${code.countryCode}`,
                    }))}
                    displayRender={(_label, selectedOption) => {
                      return `+${
                        (selectedOption?.[0]?.value as string)?.split('-')[0]
                      }`
                    }}
                  />
                </Form.Item>
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              data-testid="authenticate-button"
              disabled={isLoadingOrder && requested}
              loading={isLoadingOrder && requested}
            >
              OK
            </Button>
          </Form.Item>
        </FormContainer>
      </Form>
    </DetailCard>
  )
}

export default React.forwardRef(AuthenticateForm)
