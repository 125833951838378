import React, { createContext, PropsWithChildren, useContext } from 'react'

const EnvVarsContext = createContext<Record<string, string | undefined>>({})

export function EnvVarsProvider({
  envVars,
  children,
}: PropsWithChildren<{ envVars: Record<string, string | undefined> }>) {
  return (
    <EnvVarsContext.Provider value={envVars}>
      {children}
    </EnvVarsContext.Provider>
  )
}

export function useEnvVars() {
  const env = useContext(EnvVarsContext)

  return {
    env,
  }
}
