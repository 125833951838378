import styled from 'styled-components'

export const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .qu-map {
    height: 100%;
    width: 100%;
  }
`

export const PinOffset = styled.div`
  position: absolute;
  transform: translateY(-60px);
`

export const PinWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  overflow: visible;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
`

export const PinTop = styled.div<{ dragging: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -13px;
  transition: transform 0.2s;
  transform: translateY(${(props) => (props.dragging ? '-8px' : '0')});
`

export const PinCircle = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;
  background-color: #d6d5fc;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -46px;
`

export const PinBar = styled.div`
  position: absolute;
  background-color: #d6d5fc;
  display: block;
  width: 2px;
  height: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  top: 0;
`

export const PinPoint = styled.div`
  position: absolute;
  background-color: #d6d5fc;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  left: -3px;
  top: -3px;
`
