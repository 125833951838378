import React from 'react'
import { Typography, Card, Layout, Divider, Row, Col } from 'antd'
import { Container } from '../../styles'
import PoweredBy from '../find-order/powered-by'
import FindOrderForm from '../find-order/find-order-form'

export default function FindOrderPage() {
  return (
    <Container data-testid="find-order-form">
      <Card>
        <Layout.Content style={{ margin: '24px 12px 0', overflow: 'initial' }}>
          <Row justify="center">
            <Col>
              <Typography.Title level={2}>Track your order</Typography.Title>
            </Col>
          </Row>
          <FindOrderForm />
          <Divider />
          <PoweredBy />
        </Layout.Content>
      </Card>
    </Container>
  )
}
