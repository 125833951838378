import React, { useRef, useState } from 'react'
import { CalendarOutlined } from '@ant-design/icons'
import QuMap from '@quiqupltd/QuReactSDK/lib/components/qu-map'
import {
  CardTitle,
  HeaderInfo,
  MapScrollOverlay,
  MapWrapper,
  StatusInfo,
} from '../../../v2/styles'
import HeaderContent from './header-content'
import OrderStatesList from '../order-state-list/order-states-list'
import { useOnClickOutside } from '../utils'
import { useTracking } from '../../../hooks/useTracking'
import { externalStatus } from '../../../lib/status'

export default function Header() {
  const [mapActive, setMapActive] = useState(false)
  const [withBounds, setWithBounds] = useState(true)
  const mapRef = useRef(null)

  const { orderDetails, mapCenter, liveOrder, uniqueStateChanges, hasMap } =
    useTracking()

  useOnClickOutside(mapRef, () => setMapActive(false))

  const disableWithBound = () => {
    setWithBounds(false)
  }

  if (!orderDetails) {
    return null
  }

  return (
    <HeaderInfo mapExist={hasMap}>
      <div className="info-icon">
        <CalendarOutlined />
      </div>
      <HeaderContent
        state={externalStatus[uniqueStateChanges[0]?.toState ?? '']}
        date={
          externalStatus[uniqueStateChanges[0]?.toState ?? ''] ===
            'Delivered' || orderDetails?.serviceKind === 'partner_return'
            ? uniqueStateChanges[0]?.occurredAt
            : orderDetails?.deliveryTime?.deliveryBefore
        }
      />
      {orderDetails?.id ? (
        <StatusInfo>
          <CardTitle className="status-title">
            Order status: #{orderDetails?.id}
          </CardTitle>
          <OrderStatesList uniqueStateChanges={uniqueStateChanges} />
        </StatusInfo>
      ) : null}
      {hasMap ? (
        <MapWrapper ref={mapRef}>
          <QuMap
            geoJson={
              liveOrder?.features?.length > 0 && {
                features: liveOrder?.features,
              }
            }
            center={mapCenter}
            withBounds={withBounds}
            maxZoom={18}
            onZoom={disableWithBound}
            onDragend={disableWithBound}
            mobile={true}
          />
          <MapScrollOverlay
            isMapActive={mapActive}
            onClick={(e) => {
              setMapActive(true)
              e.stopPropagation()
            }}
          />
        </MapWrapper>
      ) : null}
    </HeaderInfo>
  )
}
