import React, { useCallback, useEffect, useRef, useState } from 'react'
import Leaflet from 'leaflet'
import { Map as LeafletMap, TileLayer } from 'react-leaflet'
import { ReactComponent as HouseIcon } from '../../../assets/icons/house-active.svg'
import {
  MapWrapper,
  PinBar,
  PinCircle,
  PinOffset,
  PinPoint,
  PinTop,
  PinWrapper,
} from './update-address-map-styled'

const token =
  'pk.eyJ1IjoiZGFubnloYXdraW5zIiwiYSI6ImNsa3Yyajh4NzBnOGIzZHJ6MHhjbzhlYjkifQ.CY2hOcM6nI1d9UTpyrWvcg'
const tileUrl = `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=${token}`

type Props = {
  deliveryCoords: {
    lat: number
    lng: number
  }
  zoom: number
  center: {
    lat: number
    lng: number
  }
  onZoomEnd: (zoom: number) => void
  onMoveEnd: (coords: { lat: number; lng: number }) => void
}

export default function UpdateAddressMap(props: Props) {
  const [visible, setVisible] = useState(false)
  const mapRef = useRef<typeof LeafletMap | null>(null)
  const [dragging, setDragging] = useState(false)
  const [mapCenter] = useState(props.center)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    let visibleTimer: ReturnType<typeof setTimeout> | undefined

    if (!visible) {
      visibleTimer = setTimeout(() => setVisible(true), 300)
    }

    return () => {
      if (visibleTimer) {
        clearTimeout(visibleTimer)
      }
    }
    // opens map after page styles are calculated so map renders properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleZoom = useCallback(() => {
    if (mapRef.current && ready) {
      const scrollWheelZoom = Leaflet.Map.ScrollWheelZoom.extend({
        _performZoom: function () {
          var map = this._map,
            zoom = map.getZoom(),
            snap = this._map.options.zoomSnap || 0

          map._stop() // stop panning and fly animations if any

          // map the delta with a sigmoid function to -4..4 range leaning on -1..1
          var d2 = this._delta / (this._map.options.wheelPxPerZoomLevel * 4),
            d3 = (4 * Math.log(2 / (1 + Math.exp(-Math.abs(d2))))) / Math.LN2,
            d4 = snap ? Math.ceil(d3 / snap) * snap : d3,
            delta = map._limitZoom(zoom + (this._delta > 0 ? d4 : -d4)) - zoom

          this._delta = 0
          this._startTime = null

          if (!delta) {
            return
          }

          const centerXY = map.project(props.deliveryCoords, zoom + delta)
          const centerLatLng = map.unproject(
            { x: centerXY.x, y: centerXY.y + 60 },
            zoom + delta
          )
          map.setView(centerLatLng, zoom + delta)
        },
      })
      mapRef.current.leafletElement.addHandler(
        'scrollWheelZoom',
        scrollWheelZoom
      )

      const zoomControl = Leaflet.Control.Zoom.extend({
        _zoomIn: function (e: any) {
          var map = this._map,
            zoom = map.getZoom()

          if (!this._disabled && this._map._zoom < this._map.getMaxZoom()) {
            var delta = this._map.options.zoomDelta * (e.shiftKey ? 3 : 1)
            const centerXY = map.project(props.deliveryCoords, zoom + delta)
            const centerLatLng = map.unproject(
              { x: centerXY.x, y: centerXY.y + 60 },
              zoom + delta
            )
            map.setView(centerLatLng, zoom + delta)
          }
        },
        _zoomOut: function (e: any) {
          var map = this._map,
            zoom = map.getZoom()

          if (!this._disabled && this._map._zoom > this._map.getMinZoom()) {
            var delta = this._map.options.zoomDelta * (e.shiftKey ? 3 : 1)
            const centerXY = map.project(props.deliveryCoords, zoom - delta)
            const centerLatLng = map.unproject(
              { x: centerXY.x, y: centerXY.y + 60 },
              zoom - delta
            )
            map.setView(centerLatLng, zoom - delta)
          }
        },
      })
      const newZoomControl = new zoomControl()
      mapRef.current.leafletElement.addControl(newZoomControl)

      return () => {
        newZoomControl.remove()
      }
    }
  }, [props.deliveryCoords, ready])

  useEffect(() => {
    const callback = handleZoom()

    return callback
  }, [handleZoom])

  if (!visible) {
    return null
  }

  return (
    <MapWrapper>
      <LeafletMap
        animate
        className={'qu-map'}
        zoom={18}
        maxZoom={18}
        minZoom={13}
        zoomControl={false}
        center={Leaflet.latLng(mapCenter.lat, mapCenter.lng)}
        onZoomEnd={(e: any) => {
          const currentZoom = e.target.getZoom()
          const centerXY = e.target.project(props.deliveryCoords, currentZoom)
          const centerLatLng = e.target.unproject(
            { x: centerXY.x, y: centerXY.y + 60 },
            currentZoom
          )
          e.target.panTo(centerLatLng)
        }}
        onDragEnd={(e: any) => {
          const currentZoom = e.target.getZoom()
          const centerXY = e.target.project(e.target.getCenter(), currentZoom)
          const centerLatLng = e.target.unproject(
            { x: centerXY.x, y: centerXY.y - 60 },
            currentZoom
          )
          props.onMoveEnd(centerLatLng)
          setDragging(false)
        }}
        onDragStart={() => {
          setDragging(true)
        }}
        whenReady={(e: any) => {
          const currentZoom = e.target.getZoom()
          const centerXY = e.target.project(props.center, currentZoom)
          const centerLatLng = e.target.unproject(
            { x: centerXY.x, y: centerXY.y + 60 },
            currentZoom
          )
          e.target.setView(centerLatLng, 18)
          setReady(true)
        }}
        ref={mapRef}
        touchZoom={false}
        scrollWheelZoom={true}
        scrollWheelPan={false}
      >
        <TileLayer showOnSelector={false} url={tileUrl} />
      </LeafletMap>
      <PinWrapper>
        <PinOffset>
          <PinTop dragging={dragging}>
            <PinBar />
            <PinCircle>
              <HouseIcon />
            </PinCircle>
          </PinTop>
          <PinPoint />
        </PinOffset>
      </PinWrapper>
    </MapWrapper>
  )
}
