import React from 'react'
import { BeatLoader } from 'react-spinners'
import styled from 'styled-components'
import theme from '../../theme'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`

export default function Spinner() {
  return (
    <Container data-testid="loading-spinner">
      <BeatLoader color={theme.cornFlower500} />
    </Container>
  )
}
