import { PhoneOutlined } from '@ant-design/icons'
import React, { forwardRef } from 'react'
import { useAnalytics } from '../../hooks/useAnalytics'
import { EventMapper } from './utils'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'
type Props = {}
const Content = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const PhoneIcon = styled(PhoneOutlined)`
  color: #6c65f7;
  background-color: #d6d5fc;
  border-radius: 99px;
  padding: 6px;
  font-size: 24px;
`

function CourierInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { track } = useAnalytics()
  const { orderDetails, liveOrder, hasMap } = useTracking()
  const shouldRender = liveOrder.quiqee?.name && hasMap

  if (!shouldRender || !orderDetails) {
    return null
  }

  const handleTrackEvent = (event: string) => {
    track(`Website Tracking Page ${EventMapper[event].event}`, {
      action: EventMapper[event].action,
      category: EventMapper[event].category,
      product: 'TrackOrder',
      orderId: String(orderDetails?.id),
    })
  }

  return (
    <DetailCard ref={ref} title={'👋 Courier'}>
      <Content>
        <div>{liveOrder?.quiqee?.name}</div>
        {liveOrder?.quiqee?.phone ? (
          <IconsContainer>
            <a
              href={`tel:${liveOrder.quiqee.phone}`}
              onClick={() => handleTrackEvent('call driver')}
            >
              <PhoneIcon />
            </a>
          </IconsContainer>
        ) : null}
      </Content>
    </DetailCard>
  )
}

export default forwardRef(CourierInfo)
