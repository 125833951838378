import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ConfigProvider } from 'antd'
import { theme } from 'antd/lib'
import { useAnalytics } from './hooks/useAnalytics'
import { OrderProvider } from './hooks/useOrder'
import FindOrderPage from './components/pages/find-order-page'
import NotFoundPage from './components/pages/not-found-page'
import OrderDetailsPage from './components/pages/order-details-page'
import { TrackingProvider } from './hooks/useTracking'

const queryClient = new QueryClient()

function App() {
  const location = useLocation()
  const { page } = useAnalytics()

  const { defaultAlgorithm, defaultSeed } = theme

  const mapToken = defaultAlgorithm({
    ...defaultSeed,
    colorPrimary: '#6C65F7',
    colorTextBase: '#2e2e2e',
    borderRadius: 4,
  })

  useEffect(() => {
    page(location.pathname)
  }, [location, page])

  return (
    <ConfigProvider
      theme={{
        token: mapToken,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <OrderProvider>
          <TrackingProvider>
            <Routes>
              <Route path="/" element={<FindOrderPage />}></Route>
              <Route path="/:uuid" element={<OrderDetailsPage />}></Route>
              <Route path="*" element={<NotFoundPage />}></Route>
            </Routes>
          </TrackingProvider>
        </OrderProvider>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
