import React, { forwardRef, useEffect } from 'react'
import { Button } from 'antd'
import { useAnalytics } from '../../hooks/useAnalytics'
import { EventMapper } from './utils'
import { useEnvVars } from '../../hooks/useEnvVars'
import { useQuery } from 'react-query'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'

type Props = {}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`

const Note = styled.div`
  color: ${theme.slate500};
  font-size: 10px;
  line-height: 21px;
`

function ShipmentInfo(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { track } = useAnalytics()
  const { env } = useEnvVars()
  const { orderDetails } = useTracking()

  const fetchShipment = async (): Promise<any> => {
    const res = await fetch(
      `${env.CARRIER_MANAGEMENT}/shipments?core_api_order_id=${orderDetails?.id}`
    )
    return res.json()
  }

  const { data, refetch: refetchShipment } = useQuery(
    'shipments',
    fetchShipment
  )

  useEffect(() => {
    if (orderDetails?.id) {
      refetchShipment()
    }
  }, [orderDetails?.id, refetchShipment])

  if (!data?.tracking_url || !orderDetails) {
    return null
  }

  const handleTrackEvent = (event: string) => {
    track(`Website Tracking Page ${EventMapper[event].event}`, {
      action: EventMapper[event].action,
      category: EventMapper[event].category,
      product: 'TrackOrder',
      orderId: String(orderDetails?.id),
    })
  }

  return (
    <DetailCard ref={ref} title={'✈️ DHL Tracking'}>
      <Content>
        <Note>Tracking number: {data?.tracking_id}</Note>

        <Button
          href={data?.tracking_url}
          onClick={() => handleTrackEvent('track dhl')}
        >
          Track Order
        </Button>
      </Content>
    </DetailCard>
  )
}

export default forwardRef(ShipmentInfo)
