import { Button, Form } from 'antd'
import styled from 'styled-components'
import { EnvironmentOutlined } from '@ant-design/icons'
import theme from '../../../theme'

export const CloseButton = styled(Button)`
  position: fixed;
  top: 12px;
  right: 18px;

  @media (min-width: ${theme.minBreakpoints.tablet}) {
    position: absolute;
  }
`

export const Container = styled.div`
  border-radius: 12px 12px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: ${theme.midnight100};
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${theme.midnight500};
  flex-direction: column;
  align-items: center;
`

export const HeaderIcon = styled(EnvironmentOutlined)`
  color: #fff;
  border-radius: 100%;
  padding: 12px;
  font-size: 24px;
  background-color: ${theme.midnight500};
  transform: translateY(-40%);
  width: fit-content;
  margin-bottom: -20px;
`

export const HeaderTitle = styled.div`
  color: #fff;
  font-size: 16px;
`

export const HeaderSubtitle = styled.div`
  color: ${theme.sunflower500};
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 200px;
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.midnight100};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
`

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${theme.midnight100};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: 100%;
`

export const AddressFormContainer = styled.div<{ pinConfirmed: boolean }>`
  position: absolute;
  bottom: 20px;
  overflow: visible;
  transition: all 0.5s ease;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  z-index: 2000;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: ${(p) => (p.pinConfirmed ? '100%' : '180px')};
`

export const AddressFormCard = styled.div<{ pinConfirmed: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  box-sizing: border-box;
  transition: all 0.5s ease;
  max-height: ${(p) => (p.pinConfirmed ? '100%' : '180px')};
  height: 100%;
  overflow: hidden;
`

export const AddressForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const AddressFieldsTitle = styled.div<{
  withShadow: boolean
  pinConfirmed: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 12px;
  line-height: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${theme.midnight500};
  padding-top: 20px;
  padding-bottom: ${(p) => (p.pinConfirmed ? '20px' : '8px')};
  padding-left: 20px;
  padding-right: 20px;
  height: fit-content;
  min-height: 64px;
  box-shadow: ${(p) =>
    p.withShadow ? '0 4px 2px -2px rgba(0, 0, 0, 0.15)' : 'unset'};
`

export const CloseDrawerButton = styled(Button)`
  font-size: 12px;
`

export const ConfirmAddressButton = styled(Button)`
  width: 100%;
`

export const BottomActions = styled.div<{
  withShadow: boolean
  pinConfirmed: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: ${(p) => (p.pinConfirmed ? '20px' : '8px')};
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: fit-content;
  box-shadow: ${(p) =>
    p.withShadow ? '0px -2px 4px rgba(0, 0, 0, 0.15)' : 'unset'};
`

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  overflow: scroll;
`

export const FormMask = styled.div<{ active: boolean }>`
  visibility: ${(p) => (p.active ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.active ? '1' : '0')};
  transform: ${(p) => (p.active ? 'scale(1)' : 'scale(0)')};
  transition: ${(p) =>
    p.active
      ? 'opacity 0.5s ease, visibility 0s linear 0s, transform 0s linear 0s'
      : 'opacity 0.5s ease, visibility 0s linear 0.5s, transform 0s linear 0.5s'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
`
