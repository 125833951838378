import { Switch, notification } from 'antd'
import React, { forwardRef, useEffect, useState } from 'react'
import ApiClient from '@quiqupltd/quiqupjs/lib/network/api-client'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useEnvVars } from '../../hooks/useEnvVars'
import { EventMapper } from './utils'
import { useTracking } from '../../hooks/useTracking'
import DetailCard from '../common/detail-card'
import styled from 'styled-components'
import theme from '../../theme'

type Props = {}

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: ${theme.midnight800};
`
function CanLeaveAtDoor(_props: Props, ref: React.Ref<HTMLDivElement>) {
  const { track } = useAnalytics()
  const { env } = useEnvVars()
  const { orderDetails, canLeaveAtDoor } = useTracking()

  const [leaveAtDoorToggle, setLeaveAtDoorToggle] = useState(
    (orderDetails?.requiredDocuments ?? []).includes('leave_at_door')
  )

  useEffect(() => {
    setLeaveAtDoorToggle(
      (orderDetails?.requiredDocuments ?? []).includes('leave_at_door')
    )
  }, [orderDetails?.requiredDocuments])

  const handleLeaveAtDoorToggle = async () => {
    try {
      const response = await ApiClient.put({
        path: `${env.EXCORE_URL}/order_management/${orderDetails?.id}/update_preference?preference=leave_at_door`,
      })

      if (!response) throw new Error('not able to update preferences')
      setLeaveAtDoorToggle(!leaveAtDoorToggle)
    } catch (error) {
      notification.error({
        message: 'Unable to update preferences',
        duration: 3,
      })
    }
  }

  if (
    !canLeaveAtDoor ||
    !orderDetails ||
    orderDetails.serviceKind === 'partner_return'
  ) {
    return null
  }

  const handleTrackEvent = (event: string) => {
    track(`Website Tracking Page ${EventMapper[event].event}`, {
      action: EventMapper[event].action,
      category: EventMapper[event].category,
      product: 'TrackOrder',
      orderId: String(orderDetails?.id),
    })
  }

  return (
    <DetailCard ref={ref} title={'🚪 Leave at door'}>
      <Content>
        <div>Leave at door</div>
        <div>
          <Switch
            data-testid="leave-at-door-toggle"
            checked={leaveAtDoorToggle}
            onChange={handleLeaveAtDoorToggle}
            onClick={() => handleTrackEvent('leave at door')}
          />
        </div>
      </Content>
    </DetailCard>
  )
}
export default forwardRef(CanLeaveAtDoor)
